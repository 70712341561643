import {PriceModel} from './Price.model';
import {PickupInfoModel} from './PickupInfo.model';
import {ShippingOptionFragment} from '../../gql/graphql';

export class SelectedShippingOptionModel {
  public code: string;
  public title: string;
  public pickupInfo?: PickupInfoModel;
  public deliveryTime: string;
  public price: PriceModel;

  constructor(shippingOption: ShippingOptionFragment) {
    this.code = shippingOption.code!;
    this.title = shippingOption.title!;
    this.pickupInfo = shippingOption?.logistics?.pickupDetails
      ? new PickupInfoModel(shippingOption?.logistics)
      : undefined;
    this.deliveryTime = shippingOption.logistics?.deliveryTime ?? '';

    this.price = new PriceModel(shippingOption.cost?.price);
  }
}
